import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Autoplay, Keyboard, Pagination, Navigation, EffectFade } from "swiper";
import img1 from "../../Images/Caraousal_Images/Main-Banner1_afterDiwali.jpg";
import img2 from "../../Images/Caraousal_Images/Main-Banner_2_afterDiwali.jpg";
import img3 from "../../Images/Caraousal_Images/mangalsutra.jpg";
  import img4 from "../../Images/Caraousal_Images/4.jpg";
  import img5 from "../../Images/Caraousal_Images/fancy-chain.jpg";


// import img5 from "../../Images/Caraousal_Images/Trending5.jpg";
// import img6 from "../../Images/Caraousal_Images/Trending6.png";

// import img5 from "../../Images/Caraousal_Images/Trending5.jpg";

import "swiper/css";
import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import { useNavigate } from "react-router-dom";
export default function Caraousal() {
  const navigate = useNavigate();
  return (
    // <div className="caraousalImagesBox1">
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      effect={"fade"}
      speed={1300}
      keyboard={{
        enabled: true,
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[EffectFade, Autoplay, Pagination, Navigation, Keyboard]}
      className="mySwiper"
    >
     


<SwiperSlide>
        <img
          onClick={() => {
            // navigate("/edition/Winter Special");
            // navigate("/specialoccasion/akshaytritiya");
            navigate("/silver-winter-collection");
          }}
          className="caraousalImages"
          src={img4}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            navigate("/silver-winter-collection");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide>

      <SwiperSlide>
        <img
          style={{ cursor: "pointer" }}
          className="caraousalImages"
          src={img5}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/gender/female/chain/gold");
          }}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/specialoccasion/diwalispecial");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide>

     

      {/* <SwiperSlide>
        <img
          onClick={() => {
            // navigate("/edition/Winter Special");
            // navigate("/specialoccasion/akshaytritiya");
            navigate("/oxidizedproducts");
          }}
          className="caraousalImages"
          src={img1}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            navigate("/oxidizedproducts");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}

      <SwiperSlide>
        <img
          style={{ cursor: "pointer" }}
          className="caraousalImages"
          src={img2}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("metal/silver/ring");
          }}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("metal/silver/ring");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide>

      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer" }}
          className="caraousalImages"
          src={img4}
          alt={"Category name"}
          onClick={() => {
            navigate(`/itemType/Ganpati%20Ornaments/silver`)
            // navigate(`/gender/${"male"}/${"chain"}`);
            // navigate("/oxidizedproducts");
          }}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            // navigate("/gender/female/chain/gold");
            // navigate("/oxidizedproducts");
            navigate(`/gender/female/mangalsutra/silver`)
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}


      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer" }}
          className="caraousalImages"
          src={img3}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/gender/female/mangalsutra/silver");
          }}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/gender/female/mangalsutra/silver");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}


      {/* <SwiperSlide>
        <img
          style={{ cursor: "pointer" }}
          className="caraousalImages"
          src={img6}
          alt={"Category name"}
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/shoppage");
          }}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            // navigate(`/gender/${"male"}/${"chain"}`);
            navigate("/shoppage");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          onClick={() => {
            navigate("/edition/Winter Special");
          }}
          className="caraousalImages"
          src={img1}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            navigate("/edition/Winter Special");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          onClick={() => {
            // navigate(`/gender/${"female"}/${"Fancy Chain"}`);
            navigate("metal/gold/chain");
          }}
          className="caraousalImages"
          src={img3}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            // navigate(`/gender/${"female"}/${"fancy chain"}`);
            navigate("metal/gold/chain");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          onClick={() => {
            navigate("/material/silver");
          }}
          className="caraousalImages"
          src={img4}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            navigate("/material/silver");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide>
      <SwiperSlide>
        <img
          onClick={() => {
            navigate("/material/silver");
          }}
          className="caraousalImages"
          src={img5}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            navigate("/material/silver");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}
      {/* <SwiperSlide>
        <img
          onClick={() => {
            navigate("/material/silver");
          }}
          className="caraousalImages"
          src={img6}
          alt={"Category name"}
        />
        <button
          className="shopNowBtn"
          onClick={() => {
            navigate("/material/silver");
          }}
        >
          SHOP NOW
        </button>
      </SwiperSlide> */}
    </Swiper>
    // </div>
  );
}
