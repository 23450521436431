import React, { useEffect } from "react";
import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import category1 from "../../Images/Categories/Small-Rectangle_600-150_3.jpg";
import category2 from "../../Images/Categories/Small-Rectangle_600-150_1.jpg";
import category5 from "../../Images/Categories/Category3.jpg";
import category4 from "../../Images/Categories/winter-2024.jpg";
import category3 from "../../Images/Categories/fancy-chain.jpg";
import category6 from "../../Images/Categories/Category6.jpg";
import { Link } from "react-router-dom";

export default function Categories() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <>
      <div className="categoriesMainContainer">
        {/* <Link to="/category/ring"> */}
        {/* <div className="category1 category2" data-aos="fade-up">
          <ComingSoonImage
            className="category"
            src={category1}
            alt="categoryName"
          />

        </div> */}
        {/* </Link> */}

        
       

        <Link to="/silver-winter-collection">
          <div className="category1 category2" data-aos="fade-up">
            <img className="category" src={category4} alt="categoryName" />
          </div>
        </Link>
        
        <Link to="/gender/female/chain/gold">
          <div className="category1 category2" data-aos="fade-up">
            <img className="category" src={category3} alt="categoryName" />
          </div>
        </Link>
        <Link to="/oxidizedproducts">
          <div className="category1 category2" data-aos="fade-up">
            <img className="category" src={category1} alt="categoryName" />
          </div>
        </Link>
        {/* <Link to="/category/chain"> */}
        {/* <Link to="/itemType/Ganpati%20Ornaments/silver"> */}
        
        <Link to="/metal/silver/ring">
          <div className="category1 category2" data-aos="fade-down">
            <img className="category" src={category2} alt="categoryName" />
          </div>
        </Link>
        <Link to="/category/anklet">
          <div className="category1 category2" data-aos="fade-down">
            <img className="category" src={category5} alt="categoryName" />
          </div>
        </Link>
        {/* <Link to="/product/fancy chain"> */}
        <Link to="/itemType/Ganpati%20Ornaments/silver">
          <div className="category1 category2" data-aos="fade-down">
            <img className="category" src={category6} alt="categoryName" />
          </div>
        </Link>
        {/* <Link to="/category/pendant"> */}
        {/* <div className="category1 category2" data-aos="fade-down">
          <ComingSoonImage
            className="category"
            src={category6}
            alt="categoryName"
          />
        </div> */}
        {/* </Link> */}
      </div>
    </>
  );
}
