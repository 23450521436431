import React, { useEffect } from "react";
import ComingSoonImage from "../../CominSoonImage/ComingSoonImage";
import otherCategory1 from "../../Images/OtherCategories/For-Him-Section.jpg";
import otherCategory2 from "../../Images/OtherCategories/Square-Mens-Banner.jpg";
import { Link } from "react-router-dom";

export default function OtherCategories() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="otherCategoriesMain">
      <Link
        to="/gender/female/chain/gold"
        className="otherCategoriesItems1"
        // data-aos="fade-left"
        // data-aos="fade-up"
        data-aos="zoom-in"
        data-aos-offset="100"
      >
        {/* <div
          className="otherCategoriesItems1"
          // data-aos="fade-left"
          // data-aos="fade-up"
          data-aos="zoom-in"
          data-aos-offset="100"
        > */}
        <img
          src={otherCategory1}
          className="otherCategoriesItems1"
          alt="Jewellery For Her"
        />
        {/* </div> */}
      </Link>


      <Link
        // to="/gender/male"
         to="/mens/silver"
        // to="#"
        className="otherCategoriesItems1"
        // data-aos="fade-right"
        data-aos="zoom-in"
        data-aos-offset="100"
      >
        {/* <div
        className="otherCategoriesItems1"
        // data-aos="fade-right"
        data-aos="zoom-in"
        data-aos-offset="100"
        > */}
        <img
          src={otherCategory2}
          className="otherCategoriesItems1"
          alt="Jewellery For Him"
        />
        {/* </div> */}
      </Link>
    </div>
  );
}
