import React, { useState } from "react";
import "../Components Styles/MobileNavbar.css";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import { BiChevronRight, BiChevronDown } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import topOffer from "../Images/Square-Mobile-View.jpg";

export default function MobileNavbar({ goldList, silverList, mobileMenu }) {
  const [goldCategories, setGoldCategories] = useState(true);
  const [silverCategories, setSilverCategories] = useState(true);
  const [allCategories, setAllCategories] = useState(false);
  const bagItems = useSelector((state) => state);
  // console.log(bagItems);
  const uniqueItem = bagItems.reducer2;
  const userDetails = bagItems.reducer4;
  const totalItems = bagItems.reducer2.length;
  const navigate = useNavigate();
  return (
    <div className="mobileNavbarMainBox">
      {/* <div className="mobileNavbarTop"> */}
      {/* <div className="mobileNavbarTopOffer"> */}
      <img
        // onClick={() => navigate("/gender/female/mangalsutra/silver")}
        onClick={() => navigate("/gender/female/chain/gold")}
        className="mobileNavbarTopOffer"
        src={topOffer}
        alt="Trending Offer Image"
      />
      {/* </div> */}
      {/* </div> */}

      <div className="mobileNavbarBody">
        <h3 onClick={() => setAllCategories(!allCategories)}>
          {/* ALL CATEGORIES */}
          ALL CATEGORIES
          {allCategories === true ? (
            <BiChevronRight
              size={"1.5rem"}
              className="mobileNavbarDropdownIcon"
            />
          ) : (
            <BiChevronDown
              size={"1.5rem"}
              className="mobileNavbarDropdownIcon"
            />
          )}
        </h3>
        <div
          className={
            allCategories === true
              ? "allCategoriesList hide"
              : "allCategoriesList"
          }
        >
          {/* {goldList.map((x, y) => (
            <Link to="/shoppage" key={y}>
              <p style={{ marginLeft: "10px" }}>{x.name}</p>
            </Link>
          ))} */}

          <h3
            style={{ marginLeft: "10px" }}
            onClick={() => setGoldCategories(!goldCategories)}
          >
            {/* ALL CATEGORIES */}
            GOLD
            {goldCategories === true ? (
              <BiChevronRight
                size={"1.5rem"}
                className="mobileNavbarDropdownIcon"
              />
            ) : (
              <BiChevronDown
                size={"1.5rem"}
                className="mobileNavbarDropdownIcon"
              />
            )}
          </h3>
          <div
            className={
              goldCategories === true
                ? "allCategoriesList hide"
                : "allCategoriesList"
            }
          >
            {goldList.map((x, y) =>
              x.name === "Fancy Chain" ? (
                <Link to={"/gender/female/chain/gold"} key={y}>
                  <p
                    onClick={() => mobileMenu()}
                    style={{ marginLeft: "10px" }}
                  >
                    {x.name}
                  </p>
                </Link>
              ) : (
                <Link to={`/metal/gold/${x.name}`} key={y}>
                  <p
                    onClick={() => mobileMenu()}
                    style={{ marginLeft: "10px" }}
                  >
                    {x.name}
                  </p>
                </Link>
              )
            )}
          </div>
          <h3
            style={{ marginLeft: "10px" }}
            onClick={() => setSilverCategories(!silverCategories)}
          >
            {/* ALL CATEGORIES */}
            SILVER
            {silverCategories === true ? (
              <BiChevronRight
                size={"1.5rem"}
                className="mobileNavbarDropdownIcon"
              />
            ) : (
              <BiChevronDown
                size={"1.5rem"}
                className="mobileNavbarDropdownIcon"
              />
            )}
          </h3>
          <div
            className={
              silverCategories ? "allCategoriesList hide" : "allCategoriesList"
            }
          >
            {silverList.map((x, y) => (
              <div key={y}>
                {x.name === "Studs / Tops" ? (
                  <Link to={`/metal/silver/${encodeURIComponent(x.name)}`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) :
                x.name === "Coins / Biscuit" ? (
                  <Link to={`/metal/silver/${encodeURIComponent(x.name)}`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) 
                : x.name === "Rings" ? (
                  <Link to={`/metal/silver/ring`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) : x.name === "Bracelet Chain Set" ? (
                  <Link
                    to={`/gender/male/${encodeURIComponent(
                      "Chain / Bracelet"
                    )}/silver`}
                  >
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) : x.name === "Mangalsutra" ? (
                  <Link to={`/gender/female/mangalsutra/silver`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) : x.name == "Ganpati Ornaments" ? (
                  <Link to={`/itemType/${x.name}/silver`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) : x.name == "Coin / Biscuit" ? (
                  <Link to={`/metal/silver/${x.name}`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                ) : (
                  <Link to={`/itemType/${x.name}/silver`}>
                    <p
                      onClick={() => mobileMenu()}
                      style={{ marginLeft: "10px" }}
                    >
                      {x.name}
                    </p>
                  </Link>
                )}
              </div>
            ))}
          </div>

          {/* <h3>
          <Link to="/category/coin">GOLD</Link>
        </h3> */}
          {/* <h3>SILVER</h3> */}
        </div>
        {/* <h3 onClick={() => mobileMenu()} style={{ fontWeight: "bold" }}>
          <Link to="/specialoccasion/Gudi Padwa Special">
            GUDI PADWA SPECIAL
          </Link>
        </h3> */}



<h3
          onClick={() => mobileMenu()}
          style={{
            fontWeight: "bold",
            backgroundColor: "#001deb",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            color: "#FF2E3C",
            marginBottom: "3%",
          }}
        >
         
          <Link
            to="/gender/female/chain/gold"
            style={{
              color: "#f6c722",
              textDecoration: "none",
              fontWeight: "bolder",
              
            }}
          >
           Gold Fancy Chain
          </Link>
        </h3> 

         <h3
          onClick={() => mobileMenu()}
          style={{
            fontWeight: "bold",
            backgroundColor: "#bc1823",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            color: "#FF2E3C",
            marginBottom: "3%",
          }}
        >
         
          <Link
            to="/silver-winter-collection"
            style={{
              color: "#fbfafb",
              textDecoration: "none",
              fontWeight: "bolder",
              
            }}
          >
           Silver Winter Collection
          </Link>
        </h3> 

        {/* <h3
          onClick={() => mobileMenu()}
          style={{
            fontWeight: "bold",
            backgroundColor: "#ED751C",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            color: "white",
            marginBottom: "3%",
          }}
        >
        
          <Link
            to="/specialoccasion/dhanterasspecial"
            style={{
              color: "white",
              textDecoration: "none",
              fontWeight: "bolder",
            }}
          >
            DHANTERAS SPECIAL
          </Link>
        </h3> */}
        <h3 onClick={() => mobileMenu()} style={{ fontWeight: "bold" }}>
          <Link to="/limitededition/Limited Edition">LIMITED EDITION</Link>
          {/* <Link to="/specialoccasion/akshaytritiya">AKSHAY TRITIYA</Link> */}
        </h3>
        <h3 onClick={() => mobileMenu()}>
          <Link to="/shoppage">WORLD OF GHARE'S</Link>
        </h3>
        <h3 onClick={() => mobileMenu()}>
          <Link to="/category/coin">BOOK A GOLD COIN </Link>
        </h3>
        <h3 onClick={() => mobileMenu()}>
          <Link to="/category/gift">GIFTS</Link>
        </h3>
      </div>
      <div className="mobileNavbarBottom">
        {userDetails.length === 0 ? (
          <div className="mobileNavbarBottomButtons">
            {/* <Link to="/customerloginpage"> */}
            <button
              onClick={() => navigate("/customerloginpage")}
              className="checkoutNewLoginFormBoxButton"
            >
              Login
            </button>
            {/* </Link> */}
            {/* <Link to="/checkoutpage2">
              <button className="addToCartBtn">Sign Up</button>
            </Link> */}
          </div>
        ) : (
          <div className="mobileNavbarBottomButtons">
            {/* <Link to="/deliverypage"> */}
            <button
              onClick={() => navigate("/deliverypage")}
              className="checkoutNewLoginFormBoxButton"
            >
              Login
            </button>
            {/* </Link> */}
            {/* <Link to="/deliverypage">
              <button className="addToCartBtn">Sign Up</button>
            </Link> */}
          </div>
        )}
        {/* <h3>My Orders</h3>
        <h3>CONTACT US</h3> */}
        {/* <Link to="/contactdetails">
        </Link> */}
      </div>
    </div>
  );
}
